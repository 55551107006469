import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createGtm } from "@gtm-support/vue-gtm";
import "./assets/main.css";

const app = createApp(App);

app.use(router).mount("#app");

app.use(
  createGtm({
    id: "GTM-5KK7B59",
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false
  })
);
