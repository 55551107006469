import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue")
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../pages/SignupPage.vue")
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../pages/PrivacyPolicy.vue")
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("../pages/TermsAndConditions.vue")
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("../pages/AboutUs.vue")
  },
  {
    path: "/disclaimer",
    name: "DisclaimerPage",
    component: () => import("../pages/DisclaimerPage.vue")
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: () => import("../pages/ThankYou.vue")
  },
  {
    path: "/success",
    name: "SuccessPage",
    component: () => import("../pages/SuccessPage.vue")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
